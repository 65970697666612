<template>
  <div>
    <v-list>
      <v-subheader class="text-h6 mt-n4"
        >¿Lo enviamos o lo retiras?</v-subheader
      >
      <v-list-item-group v-model="envio" color="primary">
        <v-list-item
          v-for="(item, i) in metodosEnvio"
          :key="i"
          :value="item.value"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-alert type="info" v-if="descripcion">{{ descripcion }}</v-alert>
    <v-btn block color="primary" v-if="envio" @click="siguiente">SIGUIENTE</v-btn>
  </div>
</template>

<script>
export default {
  name: "Comprar_Envio",
  data: () => ({
    envio: 'sucursal',
    metodosEnvio: [
      // {
      //   text: "Envio a Domicilio",
      //   value: "domicilio",
      //   icon: "mdi-home",
      //   descripcion:
      //     "Contamos con delivery propio dentro de la Ciudad de Córdoba, y despachamos a todo el país por el método de envío mas conveniente.",
      // },
      {
        text: "Retiro por Sucursal",
        value: "sucursal",
        icon: "mdi-hand-wash",
        descripcion: "Retirar por nuestra sucursal en la Ciudad de Córdoba.",
      },
    ],
  }),
  methods: {
    siguiente() {
      this.$emit("verificado", { envio: this.envio });
    },
  },
  computed: {
    descripcion() {
      let i = this.metodosEnvio.findIndex((val) => val.value == this.envio);
      return i > -1 ? this.metodosEnvio[i].descripcion : false;
    },
  },
};
</script>
