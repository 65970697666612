<template>
  <div>
    <v-list two-line v-if="!metodosEnvio">
      <v-subheader class="text-h6 mt-n4">¿Donde?</v-subheader>
      <v-skeleton-loader
        class="mx-auto"
        type="list"
        v-if="loading"
      ></v-skeleton-loader>
      <v-list-item-group v-model="direccion" color="primary" v-else>
        <v-list-item v-for="(item, i) in direcciones" :key="i" :value="item">
          <v-list-item-content>
            <v-list-item-title>{{ item.descripcion }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ item.direccion_1 }}, {{ item.direccion_2 }},
              {{ item.ciudad.nombre }},
              {{ item.provincia.nombre }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item value="nuevo">
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Nuevo domicilio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div v-else>
      <v-list two-line>
        <v-subheader class="text-h6 mt-n4">¿Como te lo enviamos?</v-subheader>
        <v-list-item-group v-model="metodoEnvio" color="primary">
          <v-list-item
            v-for="(item, i) in metodosEnvio"
            :key="i"
            :value="item"
            :disabled="!item.selectable"
          >
            <v-list-item-content>
              <v-list-item-title
                ><v-icon
                  >mdi-{{ item.service_type.id == 1 ? "truck" : "pin" }}</v-icon
                >
                {{ item.service_type.name }}</v-list-item-title
              >
              <v-list-item-subtitle class="text-right"
                ><b>$ {{ item.amounts.price_incl_tax }}</b>
                <v-icon small>mdi-clock</v-icon>
                <small
                  ><i
                    >de {{ item.delivery_time.min }} a
                    {{ item.delivery_time.max }} dias
                  </i></small
                ></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <p class="text-right">
        <v-btn text small @click="cambiarDireccion()"
          ><v-icon>mdi-pencil</v-icon> Cambiar direccion</v-btn
        >
      </p>
    </div>

    <v-btn
      block
      color="primary"
      v-if="botonSiguiente"
      @click="siguiente"
      :loading="loadingSiguiente"
      >SIGUIENTE</v-btn
    >
    <v-btn block text @click="anterior" class="mt-2"
      >VOLVER AL PASO ANTERIOR</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "Comprar_Domicilio",
  data: () => ({
    direccion: null,
    direcciones: [],
    metodosEnvio: null,
    metodoEnvio: null,
    loading: false,
    loadingSiguiente: false,
  }),
  methods: {
    async siguiente() {
      if (this.direccion == "nuevo") {
        this.$emit("nuevaDireccion");
      } else {
        this.loadingSiguiente = true;
        try {
          if (!this.metodoEnvio) {
            const cotizar = await this.axios.post("/cotizarEnvio", {
              carrito: this.$store.state.carrito,
              domicilio: this.direccion,
            });
            this.metodosEnvio = cotizar.data.all_results;
          } else {
            this.$emit("verificado", {
              direccion: this.direccion,
              metodoEnvio: this.metodoEnvio,
            });
          }
        } catch (error) {
          console.log(error);
        }
        this.loadingSiguiente = false;
      }
    },
    anterior() {
      this.$emit("anterior");
    },
    async obtenerDirecciones() {
      this.loading = true;
      try {
        const datos = await this.axios.get("/datosUsuario");
        this.direcciones = datos.data.direcciones;
        if (this.$route.params.nuevaDireccion)
          this.direccion = this.direcciones[this.direcciones.length - 1];
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    cambiarDireccion() {
      this.direccion = null;
      this.metodosEnvio = null;
      this.metodoEnvio = null;
    },
  },
  computed: {
    botonSiguiente() {
      // if(!this.direccion) return false
      // if(!this.metodosEnvio) return true
      // if(this.metodoEnvio) return true;
      return !this.direccion || (this.metodosEnvio && !this.metodoEnvio) ? false : true
    },
  },
  created() {
    this.obtenerDirecciones();
  },
};
</script>
