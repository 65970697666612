<template>
  <div>
    <v-list two-line>
      <v-subheader class="text-h6 mt-n4">Confirme su pedido</v-subheader>
      <v-list-item
        v-for="(item, i) in $store.state.carrito.productos"
        :key="i"
        :value="item"
      >
        <v-list-item-content>
          <v-list-item-title
            ><small>{{ item.cantidad }}x </small>
            <b>{{ item.nombre }}</b></v-list-item-title
          >
          <v-list-item-subtitle class="text-right"
            >$ {{ item.total }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="formulario.envio == 'domicilio'">
        <v-list-item-content>
          <v-list-item-title
            ><v-icon>mdi-truck</v-icon> Costo de envío</v-list-item-title
          >
          <v-list-item-subtitle class="text-right"
            >$ {{ costoEnvio }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-content>
          <v-list-item-title
            ><v-icon>mdi-hand-wash</v-icon> Retira por
            sucursal</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">Total</v-list-item-title>
          <v-list-item-subtitle class="text-right text-h6"
            >$ {{ total }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div class="text-right mb-2">
      <v-btn x-small text @click="$router.push('/carrito')"
        ><v-icon>mdi-pencil</v-icon> Editar pedido</v-btn
      >
    </div>
    <v-card>
      <v-alert type="info">
        <span v-if="formulario.metodoEnvio"
          >Enviaremos su pedido a
          <b v-if="formulario.metodoEnvio.id == 9"
            >la sucursal de {{ formulario.metodoEnvio.empresa }} mas cercana a
            su domicilio</b
          >
          <b v-else
            >{{ formulario.domicilio.direccion_1 }},
            {{ formulario.domicilio.ciudad.nombre }},
            {{ formulario.domicilio.provincia.nombre }} via
            {{ formulario.metodoEnvio.empresa }}</b
          ></span
        >
        <span v-else
          >Retire su pedido por <b>Av Velez Sarfield 1426, Córdoba</b> de Lunes
          a Viernes de 9 a 18 hs, y Sábados de 10 a 14 hs.</span
        >
      </v-alert>
      <v-textarea
          outlined
          label="Dejanos algun mensaje"
          filled
          auto-grow
          rows="4"
          row-height="30"
          shaped
          v-model="mensaje"
        ></v-textarea>
    </v-card>
    <v-btn
      block
      color="green"
      x-large
      dark
      @click="confirmarPedido()"
      :loading="loading"
      ><v-icon>mdi-check</v-icon> Confirmar pedido</v-btn
    >
    <v-btn block text @click="volver" class="mt-2"
      >Volver al paso anterior</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "Comprar_Subtotal",
  props: {
    formulario: { type: Object },
  },
  data: () => ({
    loading: false,
    mensaje: ""
  }),
  computed: {
    total() {
      let sumar = (suma, producto) => suma + producto.total;
      return this.$store.state.carrito.productos.reduce(sumar, this.costoEnvio);
    },
    costoEnvio() {
      return this.formulario.envio == "domicilio"
        ? this.formulario.costoEnvio
        : 0;
    },
  },
  methods: {
    volver() {
      this.$emit("anterior");
    },
    async confirmarPedido() {
      this.loading = true;
      try {
        let mensaje = this.mensaje
        let obj = {
        ...this.formulario,
        carrito: this.$store.state.carrito.productos.map((p) => ({
          _id: p._id,
          cantidad: p.cantidad,
        })),
        mensaje
      };
      const generar = await this.axios.post('/generarPedido', obj)
      if(generar.data) {
        this.$store.commit('limpiarCarrito')
        this.$router.push(`/pedido/${generar.data}`)
      }
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
  },
};
</script>
