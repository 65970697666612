<template>
  <v-container fluid fill-height>
    <v-row wrap align-center>
      <v-col>
        <v-stepper v-model="paso">
          <v-stepper-header>
            <v-stepper-step :complete="paso > 1" step="1"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="paso > 2" step="2"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="paso > 3" step="3"> </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <Comprar_Envio @verificado="paso1"></Comprar_Envio>
            </v-stepper-content>
            <v-stepper-content step="2">
              <Comprar_Domicilio
                @verificado="paso2"
                @nuevaDireccion="nuevaDireccion"
                @anterior="paso = 1"
              ></Comprar_Domicilio>
            </v-stepper-content>
            <v-stepper-content step="3">
              <Comprar_Subtotal :formulario="formulario" @anterior="paso = formulario.envio == 'sucursal' ? 1 : 2"/>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Comprar_Envio from "../components/Comprar/Envio";
import Comprar_Domicilio from "../components/Comprar/Domicilio";
import Comprar_Subtotal from "../components/Comprar/Subtotal";

import { mapMutations } from "vuex";
export default {
  name: "Comprar",
  metaInfo: {
      title: 'Realizar Compra',
    },
  components: {
    Comprar_Envio,
    Comprar_Domicilio,
    Comprar_Subtotal
  },
  data: () => ({
    paso: 1,
    formulario: {
      envio: "",
      domicilio: null,
      metodoEnvio: null,
      costoEnvio: 0
    },
  }),
  methods: {
    ...mapMutations(["abrirSnackbar"]),
    paso1(obj) {
      if (obj.envio) this.formulario.envio = obj.envio;
      this.paso = obj.envio == "sucursal" ? 3 : 2;
    },
    paso2(obj) {
      if (obj) {
        this.formulario.domicilio = obj.direccion;
        this.formulario.metodoEnvio = {
          empresa: obj.metodoEnvio.carrier.name,
          tipo: obj.metodoEnvio.service_type.name,
          id: obj.metodoEnvio.service_type.id,
        }
        this.formulario.costoEnvio = obj.metodoEnvio.amounts.price_incl_tax;
        }
      this.paso = 3;
    },
    nuevaDireccion() {
      this.$router.push({name: 'Cuenta', 
      params: {
        volver: {
          name: 'Comprar',
          params: {
            formulario: this.formulario,
            paso: this.paso
          }
        },
        nuevaDireccion: true
      }})
    }
  },
  created() {
    if (!this.$store.state.usuario) {
      this.$router.push({
        name: "Ingresar",
        params: { volver: this.$route.fullPath },
      });
      this.abrirSnackbar({
        texto: `Tenes que ingresar a tu cuenta primero.`,
        color: "red",
        timeout: 5000,
      });
    } else if (this.$store.state.carrito.productos.length == 0) {
      this.$router.push({ name: "Tienda" });
      this.abrirSnackbar({
        texto: `Tu carrito esta vacio.`,
        color: "red",
        timeout: 5000,
      });
    }
    if(this.$route.params.formulario) this.formulario = this.$route.params.formulario
    if(this.$route.params.paso) this.paso = this.$route.params.paso
  },
};
</script>
